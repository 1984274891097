<template>
    <div class="loader_area3" v-if="loader"><quote-loader/></div>
    <div class="profile_notes" v-show="!loader">
        <div class="title">
            <ul class="tab_list">
                <!-- <li @click="tab = 1" :class="{ active: tab === 1 }">Sent</li> -->
                <!-- <li @click="tab = 2" :class="{ active: tab === 2 }">Recieved</li> -->
                <li @click="params.source = 'all'" :class="{ active: params.source === 'all'}">All emails</li>
                <li @click="params.source = 'email'" :class="{ active: params.source === 'email' }">Sent by me</li>
                <li @click="params.source = ''" :class="{ active: params.source === '' }">Sent by system</li>
                <li class="preview btn1">
                    <label for="preview_message1" class="switch_option capsule_btn p-0 border-0">
                        <h5 class="mr-3">Full View</h5>
                        <input type="checkbox" id="preview_message1" v-model="fullPreview" hidden />
                        <div><span></span></div>
                    </label>
                </li>
                <li class="preview btn2">
                    <button type="button" @click="showMailpreview">Preview</button>
                </li>
            </ul>
            <div class="right_side">
                <div class="search_panel">
                    <div class="form-field">
                        <input type="text" placeholder="Search" :disabled="emailLoader" ref="search" @input="isTyping = true" v-model.trim="params.search" />
                    </div>
                    <button class="search_btn">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
                <page-filter v-model="params.per_page" />
                <button :class="selected.length ? 'delete active' : 'delete'" @click="handleDelete">
                    <i class="fas fa-trash-alt"></i>
                </button>
            </div>
        </div>
        <div class="email_wpr" v-if="tab === 1 && emails.total">
            <div class="notes_wpr">
                <ul class="card_listing py-1">
                    <li v-for="(email, e) of emails.data" :key="e">
                        <profile-card v-bind:actions="actions" :action-handler="actionHandler" :selected-row="email">
                            <h4 class="pointer" @click="viewEmail(email)"><img src="@/assets/images/email.svg">{{ email.title.length > 25 ? email.title.substring(0, 25) + '...' : email.title }}</h4>
                            <div class="card_content">
                                <p class="mb-1 pointer" @click="viewEmail(email)">
                                    {{ email.subject }}
                                    </p>
                                <label :for="`email-${e}`" class="checkbox">
                                    <input type="checkbox" :id="`email-${e}`" :value="email.id" v-model="selected" hidden>
                                    <span><i class="fas fa-check"></i></span>
                                </label>
                                <span class="badge badge-primary">{{ email.source }}</span>
                                <h5 class="mt-2">Status:</h5>
                                <span :class="`status mb-2 ${email.latest_event}`">{{ email.latest_event }}</span>
                            </div>
                            <div class="card_footer">
                                <h5>Sent on:</h5>
                                <span class="date">
                                    {{ moment.utc(email.created_at).local().format('ll | LT') }}
                                </span>
                            </div>
                        </profile-card>
                    </li>
                </ul>
                <div class="pagination" v-show="tab === 1 && emails.total">
                    <pagination v-model="params.page" :pages="emails.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="mail_preview" v-show="emails.data && emails.data.length">
                <button type="button" class="close_btn" @click="showMailpreview"><i class="fas fa-long-arrow-alt-right"></i></button>
                <label for="preview_message2" class="preview_btn2 switch_option capsule_btn p-0 border-0">
                    <h5 class="mr-3">Full View</h5>
                    <input type="checkbox" id="preview_message2" v-model="fullPreview" hidden />
                    <div><span></span></div>
                </label>
                <div class="cell">
                    <div class="content_area">
                        <div class="msg_preview">
                            <h2 v-if="Object.keys(selectedEmail).length !== 0">{{selectedEmail.subject}}</h2>
                            <h2 v-else>{{ emails.data && emails.data.length && emails.data[0].subject ? emails.data[0].subject : '' }}</h2>
                            <div class="sender_info">
                                <img :src="contact.photo ? contact.photo : require('@/assets/images/thumb/default-logo.svg')" />
                                <div class="info">
                                    <h5>{{ user.businessname ? user.businessname : 'Thrive Coach'}}</h5>
                                    <h6><span>To:</span>{{contact.name}}</h6>
                                </div>
                            </div>
                            <p v-if="Object.keys(selectedEmail).length !== 0" v-html="selectedEmail.body"></p>
                            <p v-else v-html="emails.data && emails.data.length && emails.data[0].body ? emails.data[0].body : '' "></p>
                        </div>
                        <div class="msgFooter text-center mt-2 mb-4">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link">⚡ Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com">⚡ Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com">⚡ Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="empty_list" v-if="tab === 1 && emails.total == 0 && !loader">
            <img src="@/assets/images/empty_state.svg">
            <h4>No Records Found!</h4>
        </div>
        <div class="empty_list" v-if="tab === 2">
            <h4>Coming Soon!</h4>
        </div>
        <div v-if="resend" class="edit_modal">
            <div class="edit_section setting_wpr p-0" @scroll="scrollFunc($event)">
                <div class="form_body">
                    <h2 class="title_header">Resend E-mail<button class="close_btn" @click="resend = false"><i class="fas fa-times"></i></button></h2>
                    <div class="form_content">
                        <Form @submit="handleEmailSend" v-slot="{ errors }">
                            <contact-email-component v-model="emailForm" :contacts="contact.id" :errors="errors" :success-handler="closeResendModal" ref="email-component" />
                            <div class="action_wpr p-0 mt-5">
                                <button :disabled="emailLoader" class="btn cancel_btn" type="button" @click="resend = false">Cancel</button>
                                <button :disabled="emailLoader" class="btn save_btn" @click="handleEmailSend"><i class="fa fa-spinner fa-spin" v-if="emailLoader"></i>{{ emailLoader ? ' Resending' : 'Resend' }}</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <span :class="scrollStatus ? 'scroll_indicator swing' : 'scroll_indicator'" @click="scrolldown($event, 'edit_section')"><i class="fas fa-long-arrow-alt-up"></i></span>
        </div>
    </div>

    <full-preview v-model="fullPreview">
        <div class="dashboard">
            <email-preview :email="selectedEmail" :contact="contact" />
        </div>
    </full-preview>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { Form } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    const FullPreview = defineAsyncComponent(() => import('@/components/FullPreview'))
    const ProfileCard = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProfileCard'))
    const EmailPreview = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/EmailPreview'))
    const ContactEmailComponent = defineAsyncComponent(() => import('@/pages/contact/components/EmailComponent'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    export default {
        name: 'Tab Email',

        data () {
            return {
                tab: 1,
                scrollStatus: true,
                actions: [
                    {
                        icon: 'fa-reply',
                        label: 'Resend',
                        action: 'resendEmail'
                    },
                ],
                params: {
                    page: 1,
                    per_page: 6,
                    contact_id: '',
                    search: '',
                    source: 'all',
                    contact_profile: 1,
                },
                moment,
                isTyping: false,
                view: false,
                resend: false,
                selectedEmail: {},
                selected: [],
                emailForm: {
                    contact_id: '',
                    source: 'contacts',
                    subject: '',
                    email: '',
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                fullPreview: false,
            };
        },

        props: {
            contact: Object,
        },

        components: {
            Form,
            ProfileCard,
            FullPreview,
            EmailPreview,
            ContactEmailComponent,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.getContactEmails(vm.params);
            },

            'params.source' () {
                const vm = this;

                vm.getContactEmails(vm.params);
            },

            'params.search' (val) {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getContactEmails(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getContactEmails(vm.params);
                        }
                    }
                }
            },

            emails (emails) {
                const vm  = this;

                vm.selected      = [];
                vm.selectedEmail = {};

                if ( emails && emails.data && emails.data.length ) {
                    vm.selectedEmail       =  { ...(emails.data[0]) };
                    vm.selectedEmail.email = vm.selectedEmail.body;
                }
            },
        },

        computed: mapState({
            emailLoader: state => state.contactModule.emailLoader,
            emails: state => state.contactModule.emails,
            loader: state => state.contactModule.contactProfileLoader,
            user: state => state.authModule.user,
            membership: state => state.authModule.membership,
            whiteLabel: state => state.authModule.whiteLabel,
        }),

        mounted () {
            const vm = this;

            vm.params.contact_id = vm.contact.id;
            vm.getContactEmails(vm.params);
        },

        methods: {
            ...mapActions({
                getContactEmails: 'contactModule/getEmailLogs',
                deleteEmailLogs: 'contactModule/deleteEmailLogs',
            }),

            scrollFunc (e) {
                const vm = this;
                let z = e.currentTarget;
                let a = z.clientHeight + z.scrollTop;
                let x = z.scrollHeight;

                if (a < x) {
                    vm.scrollStatus = true;
                } else {
                    vm.scrollStatus = false;
                }
            },
            scrolldown(e, el){
                let x = e.currentTarget.closest('div');
                let elm = x.querySelector(`.${el}`);
                let z = elm.clientHeight + elm.scrollTop;
                if(z < elm.scrollHeight){
                    elm.style.scrollBehavior = 'smooth';
                    elm.scrollTo(0, elm.scrollHeight);
                }else{
                    elm.style.scrollBehavior = 'smooth';
                    elm.scrollTo(0, 0);
                }
            },

            actionHandler (email, action) {
                const vm = this;

                vm[action](email);
            },

            viewEmail (email) {
                const vm = this;

                vm.view                = true;
                vm.selectedEmail       = { ...email };
                vm.selectedEmail.email = email.body;
            },

            resendEmail (email) {
                const vm = this;

                vm.emailForm.contact_id = vm.contact.id;
                vm.emailForm.subject    = email.subject;
                vm.emailForm.email      = email.body;

                vm.resend = true;
                vm.selectedEmail = email;
            },

            handlePagination (page) {
                const vm = this;

                vm.params.page = page;
                vm.getContactEmails(vm.params);
            },

            handleEmailSend () {
                const vm = this;

                const emailComponent = vm.$refs['email-component'];

                emailComponent.handleEmailSend();
            },

            closeResendModal () {
                const vm = this;

                vm.resend = false;
            },

            handleDelete () {
                const vm = this;

                const ids = vm.selected;

                const option = Helper.swalConfirmOptions('Are you sure?', "This action is permanent. You will not be able to recover these emails.", 'Delete')
                      option.preConfirm = function() {
                                              return vm.deleteEmailLogs({ ids, contact_id: vm.contact.id }).then((result) => {
                                                  if (result) {
                                                      vm.selected = [];
                                                      vm.getContactEmails(vm.params);
                                                  }
                                              });
                                          };

                Swal.fire(option);
            },
            showMailpreview(){
                const m_preview = document.querySelector('.profile_notes .mail_preview');
                if(m_preview.classList.contains('show_m_preview')){
                    m_preview.classList.remove('show_m_preview');
                }else{
                    m_preview.classList.add('show_m_preview');
                }
            }
        },
    }
</script>

<style scoped>
    .tab_list{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        flex-grow: 1;
    }
    .tab_list li{
        padding: 8px 20px;
        background: #fff;
        border-radius: 5px;
        font-size: 13px;
        line-height: 16px;
        cursor: pointer;
    }
    .tab_list li.active{
        background: #2f7eed;
        color: #fff;
    }
    .tab_list li.preview{
        background: transparent;
        padding: 0;
        margin-left: auto;
    }
    .tab_list li.preview.btn2{
        display: none;
    }
    .profile_notes{
        height: calc(100vh - 110px);
    }
    .note_card .status.failed {
        color: #f04438;
        background: rgba(240, 68, 56,.1);
    }
    .profile_notes .title{
        flex-wrap: wrap;
    }
    .email_wpr{
        display: flex;
        align-items: flex-start;
        gap: 30px;
    }
    .profile_notes .notes_wpr{
        flex: 1 1 auto;
    }
    .profile_notes .card_listing{
        flex: 1 1 auto;
        max-height: calc(100vh - 228px);
        overflow-y: scroll;
    }
    .profile_notes .card_listing::-webkit-scrollbar {
        display: none;
    }
    .profile_notes .card_listing li{
        flex: 0 0 50%;
        min-width: 280px;
    }
    .preview-content {
        padding: 15px 15px;
        font-size: 14px;
        line-height: 22px;
    }

    .preview-content p {
        font-size: 16px !important;
        line-height: 25px !important;
    }
    .edit_modal .scroll_indicator{
        left: 50%;
        transform: translateX(-50%);
        margin: 45px 0 0 0;
    }
    .edit_modal .scroll_indicator.swing{
        margin: 0 0 45px 0;
    }
    .note_card .card_content{
        height: 110px;
    }
    .mail_preview{
        flex: 0 0 370px;
        position: sticky;
        top: 0;
        margin-right: -30px;
        display: flex;
        flex-direction: column;
    }
    .mail_preview .preview_btn2{
        width: 105px;
        margin: 20px 30px 0 auto;
        display: none;
    }
    .mail_preview .close_btn{
        display: none;
    }
    .mail_preview .cell {
        background: #fff;
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        /* box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.2); */
        position: relative;
        margin: -70px auto;
        transform: scale(0.8);
    }

    .mail_preview .cell:after,
    .mail_preview .cell:before {
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }

    .mail_preview .cell:before {
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }

    .mail_preview .cell:after {
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }

    .mail_preview .cell .content_area {
        height: 610px;
        padding: 0;
        overflow-y: scroll;
        border: 1px solid #e7e7e7;
        background: #fafafb;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .mail_preview .content_area::-webkit-scrollbar {
        display: none;
    }
    .mail_preview .close_btn {
        right: calc(100% - 13px);
        left: auto;
        width: 25px;
        height: 25px;
        background: #eaeaea;
        color: #2f7fed;
        top: 15px;
        z-index: 11;
    }
    .cell .msg_preview{
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        border-radius: 10px;
        margin: 15px;
        position: relative;
    }
    .cell .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }
    .cell .msg_preview:after, .cell .sms_preview::after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .cell .sms_preview:after{
        right: 30px;
        left: auto;
    }
    .cell .msg_preview h2{
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msg_preview p, .cell .msg_preview p :deep(p){
        font-size: 16px;
        line-height: 32px;
        font-weight: 400;
        color: #0e101a;
    }
    .cell .msg_preview p :deep(h2){
        font-size: 12px;
        line-height: 18px;
        margin-bottom: 5px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msg_preview p span{
        font-size: 11px;
        line-height: 16px;
        padding: 0;
        font-weight: 500;
        color: #121525;
    }

    /* --------- for table inside mail body for mobile view --------- */

    .cell .msg_preview :deep(p table){
        width: 300px !important;
        margin: 0 !important;
    }
    .cell .msg_preview :deep(p table td){
        padding: 0 !important;
    }
    .cell .msg_preview :deep(p table td *){
        font-size: 16px !important;
        line-height: 32px !important;
        color: #0e101a !important;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
    }
    
    /* ---------------- end ----------------- */
    
    .cell .msgFooter p {
        font-size: 13px;
        line-height: 21px;
    }
    .cell .msgFooter p a{
        text-decoration: none;
    }
    .sender_info {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 15px;
    }

    .sender_info img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        border-radius: 50%;
    }

    .sender_info .info {
        margin: 0;
    }

    .sender_info .info h5 {
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }

    .sender_info .info h6 {
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }

    .sender_info .info h6 span {
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    @media(max-width: 1499px){
        .mail_preview{
            flex: 0 0 350px;
        }
    }
    @media(max-width: 1199px){
        .mail_preview{
            flex: 0 0 350px;
            max-width: 350px;
            height: 450px;
        }
        .mail_preview .cell{
            transform: scale(0.7) translateY(-7%);
            margin-left: -30px;
        }
    }
    @media(max-width: 991px){
        .mail_preview{
            position: fixed;
            right: -375px;
            top: 0;
            height: 100vh;
            background: #f5f5f5;
            width: 375px;
            z-index: 6;
            border-left: 1px solid #e9e9e9;
            transition: all 0.3s ease-in-out;
        }
        .mail_preview.show_m_preview{
            right: 0;
        }
        .tab_list li.preview.btn2{
            display: block;
        }
        .tab_list li.preview.btn1{
            display: none;
        }
        .mail_preview .preview_btn2{
            display: flex;
        }
        .mail_preview .close_btn{
            display: block;
        }
        .mail_preview .cell{
            transform: scale(0.6) translateY(-90%);
            position: relative;
            top: 50%;
            margin: 0 -40px;
        }
    }
    @media(max-width: 767px){
        .tab_list li{
            padding: 7px 15px;
            font-size: 11px;
            line-height: 13px;
        }
    }
    @media(max-width: 600px){
        .profile_notes .title .tab_list{
            width: 100%;
            margin-bottom: 15px;
        }
        .profile_notes .title .right_side{
            width: 100%;
        }
        .profile_notes .title .right_side .search_panel{
            margin: 0 auto 0 0;
        }
        .profile_notes .card_listing li{
            flex: 0 0 100%;
        }
    }

    .dashboard {
        background: #f5f5f5;
        padding: 30px;
        /* align-items: center;
        text-align: left; */
    }

    .tab .dashboard {
        padding: 30px;
        background: #fafafb;
    }

    .cell .dashboard {
        padding: 15px;
        background: #fafafb;
    }
</style>
